<body>
  <div class="image-container full-height">
    <img src="./../../../../assets/images/section1.webp" class="full-width-img" alt="...">
    <div class="gradient-overlay"></div>
    <div class="overlay">
      <div class="text-overlay-container d-flex justify-content-center align-items-end">
        <div class="text-overlay text-center p-6">
          Si necesitas aprender <span class="highlight-bold">cómo solicitar un crédito</span> o <span
            class="highlight-bold">cómo solucionar cualquier problema con tus créditos</span>, llegaste al lugar
          correcto.

          <div class="container-buton">
            <button class="btn btn-primary btn-overlay"
              onclick="window.open('https://play.google.com/store/apps/details?id=com.cobanacademy.cobanacademy&hl=es_419&pli=1', '_blank')">Descargar
              la App</button>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="image-container section2 full-height">
    <img src="./../../../../assets/images/section2.webp" class="img-fluid2" alt="...">
    <div class="gradient-overlay"></div>
    <div class="text-overlay-left">
      <h1>¡Alarmante!</h1>
      <p>El 70% de las personas que asesoramos tienen <span>problemas con sus créditos</span>, debido a la <span>falta
          de conocimientos</span> a la hora de gestionarlos.</p>
    </div>
  </div>
</body>