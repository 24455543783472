<body>
<app-navbar></app-navbar>

<section id="start-section">
  <!-- Contenido de la sección inicio -->
  <app-start></app-start>
</section>

<section id="courses-section">
  <!-- Contenido de la sección cursos -->
  <div class="row mb-4 justify-content-center">
    <div class="col-10 text-center">
      <h2 class="responsive-text"> ¿SOBRE QUÉ TIPOS DE CRÉDITOS QUERÉS APRENDER?</h2>
    </div>
  </div>
    <app-courses-section></app-courses-section>
</section>

<section id="tools-section">
  <!-- Contenido de la sección herramientas -->
  <div class="row mb-4 justify-content-center">
    <div class="col-10 text-center">
      <h2 class="responsive-text">lo que ponemos a tu disposición </h2>
    </div>
  </div>
  <app-tools-section></app-tools-section>
</section>

<section id="about-section">
  <!-- Contenido de la sección quiénes somos -->
  <div class="row mb-4 justify-content-center">
    <div class="col-10 text-center">
      <h2 class="responsive-text">¿Quiénes somos?</h2>
    </div>
  </div>
  <app-us-section></app-us-section>
</section>

<section id="subscription-section">
  <!-- Contenido de la sección suscripción -->
  <div class="row mb-4 justify-content-center">
    <div class="col-10 text-center">
      <h2 class="responsive-text">Suscripción</h2>
    </div>
  </div>
  <app-subscription-section></app-subscription-section>
</section>

<app-footer></app-footer>
</body>
