import { Component } from '@angular/core';

@Component({
  selector: 'app-subscription-section',
  templateUrl: './subscription-section.component.html',
  styleUrl: './subscription-section.component.css'
})
export class SubscriptionSectionComponent {

}
