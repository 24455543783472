<footer class="footer text-center text-lg-start">
    <div class="container">
        <div class="row">
            <!-- First Column -->
            <div class="col-lg-6 col-md-12 mb-4 mb-md-0 text-lg-left">
                <h5 class="text">
                    <img src="assets/icons/logo.svg" alt="Cobanacademy" class="img-fluid">
                    <span class="d-none d-lg-inline custom-font">obanacademy</span>
                </h5>
                <div class="mt-3">
                    <a href="https://www.facebook.com/cobanacademy" target="_blank" class="icon-spacing"><img src="assets\icons\Facebook.png" alt="Facebook" style="width: 24px;"></a>
                        <a href="https://www.linkedin.com/company/104275279/admin/page-posts/published/" target="_blank" class="icon-spacing"><img src="assets\icons\Linkeind.png" alt="LinkedIn" style="width: 24px;"></a>
                        <a href="https://x.com/CobanAcademy" target="_blank" class="icon-spacing"><img src="assets\icons\Twitter.png" alt="Twitter" style="width: 24px;"></a>
                        <a href="https://www.instagram.com/cobanacademy/" target="_blank" class="icon-spacing"><img src="assets\icons\Instagram.png" alt="Instagram" style="width: 24px;"></a>
                        <a href="https://www.youtube.com/@CobanAcademy" target="_blank"><img src="assets\icons\Youtube.png" target="_blank" alt="YouTube" style="width: 24px;"></a>
                </div>
                <div class=" text-lg-left  py-3 footer-copyright">
                    © 2024 Todos los derechos reservados - Cobanacademy S. R. L.
                </div>
            </div>
            <!-- Second Column -->
            <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="text">Enlaces de Ayuda</h5>
                <ul class="list-unstyled enlaces-ayuda">
                    <li><a href="https://wa.me/+59177889320" target="_blank">Contacto</a></li>
                    <li><a href="https://cobanacademy.com/politica-privacidad.html" target="_blank">Términos y Condiciones</a></li>
                    <li><a href="https://cobanacademy.com/politica-privacidad.html" target="_blank">Política de Privacidad</a></li>
                </ul>
            </div>
            <!-- Third Column -->
            <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="text footer-heading">Descarga Nuestra App</h5>
                <a href="https://play.google.com/store/apps/details?id=com.cobanacademy.cobanacademy&hl=es_419&pli=1" target="_blank" class="app-download-card">
                    <img alt="Tool Image" name="logo GooglePlay" src="assets\icons\GooglePlay.svg">
                </a>
            </div>
        </div>
    </div>
</footer>
