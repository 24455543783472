import { Component } from '@angular/core';

@Component({
  selector: 'app-courses-section',
  templateUrl: './courses-section.component.html',
  styleUrl: './courses-section.component.css'
})
export class CoursesSectionComponent {

}
