<div class="gradient-container">
  <div class="menu">
    <ul>
      <li *ngFor="let tool of tools; let i = index" (mouseover)="onMouseOver(i)" (mouseout)="onMouseOut()" >
        {{ tool.name }}
      </li>
    </ul>
  </div>
  <div class="image-container" *ngIf="currentImage && !isMobile">
    <img [src]="currentImage" alt="Tool Image">
  </div>
  <div class="mobile-view" *ngIf="isMobile">
    <div *ngFor="let tool of tools">
      <h3>{{ tool.name }}</h3>
      <img [src]="tool.image" alt="Tool Image">
    </div>
  </div>
</div>
