import { Component } from '@angular/core';

@Component({
  selector: 'app-us-section',
  templateUrl: './us-section.component.html',
  styleUrl: './us-section.component.css'
})
export class UsSectionComponent {

}
