<div class="row mb-4 bg-custom">
    <div class="col-lg-4 col-md-6 col-12 mb-3 position-relative">
        <img src="../../../../assets/images/Celular1.webp" class="img-fluid" alt="Asalariados">
        <button class="btn btn-primary btn-overlay"onclick="window.open('https://play.google.com/store/apps/details?id=com.cobanacademy.cobanacademy&hl=es_419&pli=1', '_blank')">Asalariados</button>
    </div>

    <div class="col-lg-4 col-md-6 col-12 mb-3 position-relative">
        <img src="../../../../assets/images/Celular2.webp" class="img-fluid" alt="Profesionales">
        <button class="btn btn-primary btn-overlay"onclick="window.open('https://play.google.com/store/apps/details?id=com.cobanacademy.cobanacademy&hl=es_419&pli=1', '_blank')">Profesionales independientes</button>
    </div>

    <div class="col-lg-4 col-md-6 col-12 mb-3 position-relative">
        <img src="../../../../assets/images/Celular3.webp" class="img-fluid" alt="Negocio">
        <button class="btn btn-primary btn-overlay" onclick="window.open('https://play.google.com/store/apps/details?id=com.cobanacademy.cobanacademy&hl=es_419&pli=1', '_blank')">Negocio o empresa</button>
    </div>
</div>
