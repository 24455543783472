import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-tools-section',
  templateUrl: './tools-section.component.html',
  styleUrl: './tools-section.component.css'
})
export class ToolsSectionComponent implements OnInit {
  tools = [
    { name: 'Cursos en Video Pregrabados', image: 'assets/icons/Icono1.svg' },
    { name: 'Inteligencia Artificial', image: 'assets/icons/Icono2.svg' },
    { name: 'Chat 24/7', image: 'assets/icons/Icono3.svg' },
    { name: 'Asesorias Presenciales', image: 'assets/icons/Icono4.svg' },
    { name: 'Asesorias Virtuales', image: 'assets/icons/Icono5.svg' },
    { name: 'Eventos en Vivo', image: 'assets/icons/Icono6.svg' }
  ];

  currentImage: string = this.tools[0].image;
  isMobile: boolean = false;

  ngOnInit() {
    this.checkWindowSize();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkWindowSize();
  }

  checkWindowSize() {
    this.isMobile = window.innerWidth <= 768;
  }

  onMouseOver(index: number) {
    this.currentImage = this.tools[index].image;
  }

  onMouseOut() {
    this.currentImage = this.tools[0].image;
  }
}
