<div class="gradient-container">
    <div class="row">
        <div class="card">
            <div class="card-image">
                <img src="assets/images/herman.webp" alt="Herman Mercado">
            </div>
            <div class="card-content">
                <h2>Herman Mercado</h2>
                <br>
                <ul>
                    <p>Más de 27 años de experiencia en banca.</p>
                    <p>5 Libros Publicados sobre temas crediticios</p>
                </ul>
                <p class="card-role">CEO y Cofounder</p>
            </div>
        </div>
        <div class="card">
            <div class="card-image">
                <img src="assets/images/victor.webp" alt="Victor Hugo Rivera">
            </div>
            <div class="card-content">
                <h2>Victor Hugo Rivera</h2>
                <br>
                <ul>
                    <p>18 Años de experiencia en banca.</p>
                    <p>12 Años formador / Banca</p>
                </ul>
                <p class="card-role">COO y Cofounder</p>
            </div>
        </div>
        <div class="card">
            <div class="card-image">
                <img src="assets/images/uwil.webp" alt="Uwil Cuellar">
            </div>
            <div class="card-content">
                <h2>Uwil Cuellar</h2>
                <br>
                <ul>
                    <p>4 Años de Desarrollo multiplataforma</p>
                    <p>2 Años de Integración en herramientas IA</p>
                </ul>
                <p class="card-role">CTO y Cofounder</p>
            </div>
        </div>
        <div class="card">
            <div class="card-image">
                <img src="assets/images/cimar.webp" alt="Cimar Gonzales">
            </div>
            <div class="card-content">
                <h2>Cimar Gonzales</h2>
                <br>
                <ul>
                    <p>32 Años como empresario</p>
                    <p>15 Años Introducción y posicionamiento de marcas</p>
                </ul>
                <p class="card-role">CMO y Cofounder</p>
            </div>
        </div>
    </div>
</div>
