<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <a class="navbar-brand d-flex align-items-center" href="#">
      <img src="assets/icons/logo.svg" alt="Logo" class="me-1">
      <span class="logo-text">obanacademy</span>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link" ngxPageScroll href="#start-section">Inicio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" ngxPageScroll href="#courses-section">Nuestros Cursos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" ngxPageScroll href="#tools-section">Nuestras Herramientas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" ngxPageScroll href="#about-section">¿Quiénes Somos?</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" ngxPageScroll href="#subscription-section">Suscripción</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
