import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { StartComponent } from './components/home/start-section/start.component';
import { CoursesSectionComponent } from './components/home/courses-section/courses-section.component';
import { ToolsSectionComponent } from './components/home/tools-section/tools-section.component';
import { SubscriptionSectionComponent } from './components/home/subscription-section/subscription-section.component';
import { UsSectionComponent } from './components/home/us-section/us-section.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    StartComponent,
    CoursesSectionComponent,
    ToolsSectionComponent,
    SubscriptionSectionComponent,
    UsSectionComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    NgxPageScrollCoreModule.forRoot({ duration: 750 })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
