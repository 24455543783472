<div class="gradient-container">
    <h4>Elige el plan que más te conviene y accede a todos nuestros beneficios</h4>
    <br>
    <div class="container-planes">
        <div class="card">
            <p class="price">Bs. 177</p>
            <li>Cursos en Video</li>
            <li>Inteligencia Artificial</li>
            <li>Consultas Gratuitas</li>
            <p class="days">Todo por 90 días</p>
        </div>
        <div class="card">
            <p class="price">Bs. 207</p>
            <li>Cursos en Video</li>
            <li>Inteligencia Artificial</li>
            <li>Consultas Gratuitas</li>
            <p class="days">Todo por 180 días</p>
        </div>
        <div class="card">
            <p class="price">Bs. 277</p>
            <li>Cursos en Video</li>
            <li>Inteligencia Artificial</li>
            <li>Consultas Gratuitas</li>
            <p class="days">Todo por 360 días</p>
            <div class="bonus">+1 Asesoría</div>
        </div>
    </div>
</div>
